import { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import api from "../../services/api";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Button, Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import PlanModal from "../../components/PlanModal";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../../components/ConfirmationModal";

const Plans = () => {
    const [plans, setPlans] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const fetchPlans = async () => {
        const { data: { plans } } = await api.get("/plans");
        setPlans(plans);
    };
    useEffect(() => {
        fetchPlans();
    }, []);    
    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPlan(null);
        fetchPlans();
    };    
    const handleDeletePlan = async (planId) => {
        try {
            await api.delete(`/plans/${planId}`);
        } catch (e) {
            toastError(e);
        }
    }
    const handleCloseModalConfirm = (isOpen) => {        
        setModalConfirmOpen(isOpen);
        setSelectedPlan(null);
        fetchPlans();
    };
    return (
        <MainContainer>
            <ConfirmationModal
                title={i18n.t("plans.confirmationModal.deleteTitle")}
                open={modalConfirmOpen}
                onClose={handleCloseModalConfirm}
                onConfirm={() => handleDeletePlan(selectedPlan.id)}
            >
                {i18n.t("plans.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <PlanModal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="form-dialog-title"
                planId={selectedPlan?.id}
            />
            <MainHeader>
                <Title>{i18n.t("plans.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setModalOpen(true)}
                    >
                        {i18n.t("plans.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("plans.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.users")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.connections")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.queues")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.isIntegrationMode")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.price")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("plans.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {plans.map(plan => (
                                <TableRow key={plan.id}>
                                    <TableCell align="center">{plan.name}</TableCell>
                                    <TableCell align="center">{plan.users}</TableCell>
                                    <TableCell align="center">{plan.connections}</TableCell>
                                    <TableCell align="center">{plan.queues}</TableCell>
                                    <TableCell align="center"><Checkbox color="primary" checked={plan.isIntegrationMode} /></TableCell>
                                    <TableCell align="center">{i18n.t("currencyFormat", { value: plan.value })}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedPlan(plan);
                                                setModalOpen(true);                                                
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        {plan.status && (
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setSelectedPlan(plan);
                                                    setModalConfirmOpen(true);
                                                }}
                                            >
                                                <DeleteOutline />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Plans;