import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import { Picker } from 'emoji-mart'
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  Chip,
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Popover,
  Switch,
  useTheme,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import { StatusTicket } from "../../utils/constants";
import MessageMedia from "../Message/MessageMedia";
import MarkdownWrapper from "../MarkdownWrapper";
import { useDebounce } from "../../hooks/useDebounce";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },
  newMessageBox: {
    backgroundColor: theme.palette.background.inverse,
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },
  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },
  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },
  sendMessageIcons: {
    color: "grey",
  },
  uploadInput: {
    display: "none",
  },
  viewMediaInputWrapper: {
    width: "100%",
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "start",
    alignItems: "center",
    background: theme.palette.background.default,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8"
  },
  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },
  audioLoading: {
    color: green[500],
    opacity: "70%",
  },
  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },
  cancelAudioIcon: {
    color: "red",
  },
  sendAudioIcon: {
    color: "green",
  },
  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
    paddingBottom: 8,
    background: theme.palette.background.default,
  },
  replyginMsgContainer: {    
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: theme.palette.background.inverse,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },
  replyginMsgBody: {
    width: "100%",
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },
  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },
  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  }
}));

const MessageInput = ({ ticketStatus }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { ticketId } = useParams();
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [anchorQuickAnswers, setAnchorQuickAnswers] = useState(null);
  const quickAnswerItemsRef = useRef([]);
  const [quickAnswerSelectedIndex, setQuickAnswerSelectedIndex] = useState(-1);  
  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);
  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);  
  useEffect(() => {
    if (anchorQuickAnswers && quickAnswers.length === 0) handleCloseQuickAnswer();
    if (!anchorQuickAnswers && quickAnswers.length > 0 && inputMessage.indexOf("/") >= 0) setAnchorQuickAnswers(inputRef.current);
    const handleKeyDown = (event) => {
      if (quickAnswers.length === 0) return;      
      // eslint-disable-next-line
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setQuickAnswerSelectedIndex(prev => (prev + 1) % quickAnswers.length)
          break;
        case "ArrowUp":
          event.preventDefault();
          setQuickAnswerSelectedIndex(prev =>
            prev - 1 < 0
              ? quickAnswers.length - 1
              : prev - 1)
          break;
        case "Enter":
          event.preventDefault();
          if (quickAnswerSelectedIndex >= 0) {
            handleQuickAnswersClick(quickAnswers[quickAnswerSelectedIndex]);
          }
          break;
        case "Escape":
          handleCloseQuickAnswer();
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line
  }, [quickAnswers]);
  useEffect(() => {
    const indexKey = inputMessage.indexOf("/");
    if (anchorQuickAnswers && indexKey < 0) handleCloseQuickAnswer();
    if (indexKey < 0) return;
    handleLoadQuickAnswer();
    // eslint-disable-next-line
  }, [inputMessage]);
  useEffect(() => {
    if (quickAnswerSelectedIndex < 0) return;
    quickAnswerItemsRef.current[quickAnswerSelectedIndex]?.focus();
  }, [quickAnswerSelectedIndex]);  
  useEffect(() => {
    const indexKey = inputMessage.indexOf("/");
    if (anchorQuickAnswers && indexKey < 0) handleCloseQuickAnswer();
    if (indexKey < 0) return;
    handleLoadQuickAnswer();
    // eslint-disable-next-line
  }, [inputMessage]);
  const handleChangeInput = (e) => {    
    setInputMessage(e.target.value);
  };
  /** 
   * @param {HTMLInputElement} e   
  */  
  const handleKeyInput = (e) => {
    if (loading) return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }

  const handleQuickAnswersClick = (value) => {    
    setInputMessage(prev => {
      const indexKey = prev.indexOf("/");
      if (indexKey === 0) return value;
      return `${prev.substring(0, indexKey)}${value}`;
    });    
    handleCloseQuickAnswer();
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    e.preventDefault();
    if (!e.target.files) return;
    const selectedMedias = Array.from(e.target.files);
    setMedias(prev => {
      return [...prev, ...selectedMedias];
    });
  };

  const handleInputPaste = (e) => {    
    if (e.clipboardData.files.length === 0) return;
    const files = [...e.clipboardData.files];
    setMedias(prev => {
      const filteredFiles = files.filter(file => !prev.map(media => media.name).includes(file.name));
      return [...prev, ...filteredFiles];
    });
  };

  const handleDeleteMedia = (index) => {
    const currentMedias = [...medias];
    currentMedias.splice(index, 1);
    setMedias(currentMedias);
  };

  const handleSendMessage = async () => {
    const hasMessage = inputMessage.trim() !== "";
    const hasMedia = medias.length > 0;
    const hasAudio = recording;
    let mp3;
    let isValidAudio = false;
    if (hasAudio) {
      mp3 = await Mp3Recorder.stop().getMp3();
      const [, blob] = mp3;
      isValidAudio = blob.size >= 10000;
    }
    if (!hasMessage && !hasMedia && !recording && !isValidAudio) return;
    setLoading(true);
    let data;
    const message = {
      read: true,
      fromMe: true,
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage
        ? {
          fromMe: replyingMessage.fromMe,
          isGroup: replyingMessage.contact.isGroup,
          quotedMsgId: replyingMessage.id
        }
        : undefined,
    };
    if (hasMedia || hasAudio) {
      data = new FormData();
      data.append("body", JSON.stringify(message));
      if (hasAudio) {
        const fileName = `${new Date().getTime()}.mp3`;
        const [, blob] = mp3;
        data.append("medias", blob, fileName);
      } else if (hasMedia) {
        medias.forEach((media) => {
          data.append("medias", media);
        });
      }
    }
    try {
      await api.post(`/messages/${ticketId}`, data ?? message);
    } catch (err) {
      toastError(err);
    }
    setInputMessage("");
    setShowEmoji(false);
    setReplyingMessage(null);
    setMedias([]);
    setRecording(false);
    setLoading(false);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };
  
  const handleLoadQuickAnswer = useDebounce(async () => {
    const indexKey = inputMessage.indexOf("/");
    if (indexKey < 0) return;
    try {
      const { data } = await api.get("/quickAnswers/", {
        params: { searchParam: inputMessage.substring(indexKey + 1) },
      });
      setQuickAnswer(data.quickAnswers);
    } catch (err) {
      toastError(err);
    }
  }, 500);

  const handleCloseQuickAnswer = () => {
    setAnchorQuickAnswers(null);
    setQuickAnswerSelectedIndex(-1);
  }

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            <MessageMedia message={message} />
            <MarkdownWrapper>{message.body}</MarkdownWrapper>
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  const renderUploadFile = () => {
    return (
      <div className={classes.viewMediaInputWrapper}>
        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : medias.map((media, index) => (
          <Chip key={index} label={media.name} onDelete={() => handleDeleteMedia(index)} />
        ))}
      </div>
    );
  }

  return (
    <Paper square elevation={0} className={classes.mainWrapper}>
      {replyingMessage && renderReplyingMessage(replyingMessage)}
      {medias.length > 0 && renderUploadFile()}
      <div className={classes.newMessageBox}>
        <Hidden only={["sm", "xs"]}>
          <IconButton
            aria-label="emojiPicker"
            component="span"
            disabled={loading || recording || ticketStatus !== StatusTicket.OPEN}
            onClick={(e) => setShowEmoji((prevState) => !prevState)}
          >
            <MoodIcon className={classes.sendMessageIcons} />
          </IconButton>
          {showEmoji ? (
            <div className={classes.emojiBox}>
              <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                <Picker
                  perLine={16}
                  showPreview={false}
                  showSkinTones={false}
                  onSelect={handleAddEmoji}
                  theme={theme.palette.type}
                />
              </ClickAwayListener>
            </div>
          ) : null}
          <input
            multiple
            type="file"
            id="upload-button"
            disabled={loading || recording || ticketStatus !== StatusTicket.OPEN}
            className={classes.uploadInput}
            onChange={handleChangeMedias}
          />
          <label htmlFor="upload-button">
            <IconButton
              aria-label="upload"
              component="span"
              disabled={loading || recording || ticketStatus !== StatusTicket.OPEN}
            >
              <AttachFileIcon className={classes.sendMessageIcons} />
            </IconButton>
          </label>
          <FormControlLabel
            style={{ marginRight: 7, color: "gray" }}
            label={i18n.t("messagesInput.signMessage")}
            labelPlacement="start"
            disabled={ticketStatus !== StatusTicket.OPEN}
            control={
              <Switch
                size="small"
                checked={signMessage}
                onChange={(e) => {
                  setSignMessage(e.target.checked);
                }}
                name="signMessage"
                color="primary"
              />
            }
          />
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenMenuClick}
          >
            <MoreVert></MoreVert>
          </IconButton>
          <Menu
            id="simple-menu"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuItemClick}
          >
            <MenuItem onClick={handleMenuItemClick}>
              <IconButton
                aria-label="emojiPicker"
                component="span"
                disabled={loading || recording || ticketStatus !== StatusTicket.OPEN}
                onClick={(e) => setShowEmoji((prevState) => !prevState)}
              >
                <MoodIcon className={classes.sendMessageIcons} />
              </IconButton>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <input
                multiple
                type="file"
                id="upload-button"
                disabled={loading || recording || ticketStatus !== StatusTicket.OPEN}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
              />
              <label htmlFor="upload-button">
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={loading || recording || ticketStatus !== StatusTicket.OPEN}
                >
                  <AttachFileIcon className={classes.sendMessageIcons} />
                </IconButton>
              </label>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <FormControlLabel
                style={{ marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                disabled={ticketStatus !== StatusTicket.OPEN}
                control={
                  <Switch
                    size="small"
                    checked={signMessage}
                    onChange={(e) => {
                      setSignMessage(e.target.checked);
                    }}
                    name="signMessage"
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </Hidden>
        <div className={classes.messageInputWrapper}>
          <InputBase
            inputRef={(input) => {
              if (!input) return;
              input.focus();
              inputRef.current = input;
            }}
            className={classes.messageInput}
            placeholder={
              ticketStatus === StatusTicket.OPEN
                ? i18n.t("messagesInput.placeholderOpen")
                : i18n.t("messagesInput.placeholderClosed")
            }
            multiline
            maxRows={5}
            value={inputMessage}
            onChange={handleChangeInput}
            disabled={recording || loading || ticketStatus !== StatusTicket.OPEN}
            onPaste={(e) => {
              ticketStatus === StatusTicket.OPEN && handleInputPaste(e);
            }}
            onKeyDown={handleKeyInput}
          />
          <Popover
            open={Boolean(anchorQuickAnswers)}
            anchorEl={anchorQuickAnswers}
            onClose={handleCloseQuickAnswer}
            keepMounted
            disableAutoFocus
            disableEnforceFocus
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                width: anchorQuickAnswers?.offsetWidth || undefined,
              },
            }}
          >
            {quickAnswers.map((quickAnswer, index) => (
              <MenuItem
                key={index}
                ref={(el) => quickAnswerItemsRef.current[index] = el}
                selected={index === quickAnswerSelectedIndex}
                onClick={() => handleQuickAnswersClick(quickAnswer.message)}
                tabIndex={-1}
              >
                <span
                  style={{
                    fontSize: "0.9rem"
                  }}
                >
                  {i18n.t("quickAnswersModal.form.shortcut")}:
                  <span
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      color: "#6bcbef",
                      fontWeight: 500
                    }}
                  >
                    {quickAnswer.shortcut}
                  </span>
                </span>
                {`${quickAnswer.message}`}
              </MenuItem>
            ))}
          </Popover>
        </div>
        {inputMessage || medias.length > 0 ? (
          <IconButton
            aria-label="sendMessage"
            component="span"
            onClick={handleSendMessage}
            disabled={loading}
          >
            <SendIcon className={classes.sendMessageIcons} />
          </IconButton>
        ) : recording ? (
          <div className={classes.recorderWrapper}>
            <IconButton
              aria-label="cancelRecording"
              component="span"
              fontSize="large"
              disabled={loading}
              onClick={handleCancelAudio}
            >
              <HighlightOffIcon className={classes.cancelAudioIcon} />
            </IconButton>
            {loading ? (
              <div>
                <CircularProgress className={classes.audioLoading} />
              </div>
            ) : (
              <RecordingTimer />
            )}
            <IconButton
              aria-label="sendRecordedAudio"
              component="span"
              onClick={handleSendMessage}
              disabled={loading}
            >
              <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
            </IconButton>
          </div>
        ) : (
          <IconButton
            aria-label="showRecorder"
            component="span"
            disabled={loading || ticketStatus !== StatusTicket.OPEN}
            onClick={handleStartRecording}
          >
            <MicIcon className={classes.sendMessageIcons} />
          </IconButton>
        )}
      </div>
    </Paper>
  );
};

export default MessageInput;
